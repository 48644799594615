@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');
html {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: #1d8c23;
    /* overflow: hidden; */
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  display: grid;
  height: 100%;
  place-items: center;
  background-color: #f7f7f79e;
}
#checker {
  position: relative;
  height: 125px;
  width: 125px;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-left-color: #f98b88;
  animation: rotate 1.2s linear infinite;
}
@keyframes rotate {
  50% {
    border-left-color: #9b59b6;
  }
  75% {
    border-left-color: #e67e22;
  }
  100% {
    transform: rotate(360deg);
  }
}
#checker .check-icon {
  display: none;
}
#checker .check-icon:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 28px;
  transform: scaleX(-1) rotate(135deg);
  height: 56px;
  width: 28px;
  border-top: 4px solid #3c1a5b;
  border-right: 4px solid #3c1a5b;
  transform-origin: left top;
  animation: check-icon 2s ease;
}
@keyframes check-icon {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 28px;
    opacity: 1;
  }
  40% {
    height: 56px;
    width: 28px;
    opacity: 1;
  }
  100% {
    height: 56px;
    width: 28px;
    opacity: 1;
  }
}
.success {
  display: none;
}
.success ~ #checker .check-icon {
  display: block;
}
.success ~ #checker {
  animation: none;
  border-color: #f98b88;
  transition: border 20s ease-out;
}

.slider {
  background-color: red;
}
